import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '../../utils/cn';

interface LogoProps {
  className?: string;
}

const Logo = ({ className }: LogoProps) => {
  return (
    <Link to="/" className={cn('flex items-center', className)}>
      <img src="/logo/logo-min.svg" alt="1PayT Logo" className="h-16 w-auto" />
    </Link>
  );
};

export default Logo;