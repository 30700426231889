import React from 'react';

export type Language = 'en' | 'fr';

interface TranslationContextType {
  language: Language;
  setLanguage: (lang: Language) => void;
}

export const LanguageContext = React.createContext<TranslationContextType>({
  language: 'en',
  setLanguage: () => {},
});

const translations = {
  en: {
    nav: {
      features: 'Features',
      ecosystem: 'Ecosystem',
      tokenIntegration: 'Token Integration',
      faq: 'FAQ',
      support: 'Support'
    },
    hero: {
      title: 'Universal Onramp Solution',
      subtitle: 'Connect to major fiat gateways globally through a single aggregator API and widget.',
      cta: 'Get Started'
    },
    features: {
      title: 'Key Features',
      subtitle: 'Everything you need to accept crypto payments',
      api: {
        title: 'Simplified API Integration',
        description: 'Streamlined API integration for a seamless user experience'
      },
      ecosystem: {
        title: 'Complete Ecosystem',
        description: 'Hybrid-Ramp engine for optimized transactions'
      },
      onboarding: {
        title: 'Global Onboarding',
        description: 'Streamlined global user onboarding process'
      },
      tokens: {
        title: 'Token Support',
        description: 'Support for over 9,000 different tokens'
      },
      scalability: {
        title: 'Enterprise Scalability',
        description: 'Scalable solutions for businesses of all sizes'
      },
      security: {
        title: 'Maximum Security',
        description: 'Maximum protection for your transactions'
      }
    },
    ecosystem: {
      title: 'Complete Ecosystem',
      subtitle: 'A comprehensive solution for all your crypto payment needs',
      wallet: 'Secure Multi-Currency Wallet',
      exchange: 'Built-in Exchange Service',
      security: 'Advanced Security Features',
      speed: 'Lightning-Fast Transactions'
    },
    tokenIntegration: {
      title: 'Token Integration',
      subtitle: 'Support for thousands of tokens across multiple blockchains',
      stats: {
        tokens: '9,000+ Tokens',
        networks: '15+ Networks',
        transactions: '1M+ Daily Transactions',
        speed: '<2s Processing Time'
      },
      integration: {
        title: 'Simple Integration',
        subtitle: 'Start accepting crypto payments with just a few lines of code',
        codeComment: '// Integration example',
        codeExample: `const payment = await 1payt.createPayment({
  amount: 100,
  currency: 'EUR',
  tokens: ['ETH', 'USDT', 'BTC']
});`
      }
    },
    faq: {
      title: 'Frequently Asked Questions',
      subtitle: 'Find answers to common questions about 1PayT',
      questions: {
        integration: 'How do I integrate 1PayT?',
        fees: 'What are the transaction fees?',
        countries: 'Which countries are supported?',
        security: 'How secure is the platform?',
        processing: 'What is the processing time?',
        testApi: 'Can I test the API first?'
      },
      answers: {
        integration: 'Integration is simple with our SDK and comprehensive documentation.',
        fees: 'We offer competitive fees starting at 0.5% per transaction.',
        countries: 'We support over 180 countries worldwide.',
        security: 'We use bank-grade security with multi-signature protection.',
        processing: 'Most transactions are processed in under 2 seconds.',
        testApi: 'Yes, we provide a sandbox environment for testing.'
      },
      notFound: 'Didn\'t find what you\'re looking for?',
      contact: 'Contact our support team'
    },
    support: {
      title: 'Customer Support',
      subtitle: 'Our team is here to help you every step of the way',
      channels: {
        chat: {
          title: 'Live Chat',
          description: 'Chat with our team in real-time'
        },
        email: {
          title: 'Email Support',
          description: 'Send us an email for detailed assistance'
        },
        docs: {
          title: 'Documentation',
          description: 'Browse our comprehensive documentation'
        },
        dedicated: {
          title: 'Dedicated Support',
          description: 'Personalized support for enterprises'
        }
      },
      contactButton: 'Contact Support'
    },
    supportPage: {
      title: 'Contact Our Support Team',
      subtitle: 'Our team is here to help. We will respond as soon as possible.',
      formTitle: 'How can we help you?',
      formDescription: 'Fill out the form below with your request details. Our technical support team will respond within 24 business hours.',
      info: {
        phone: {
          title: 'Phone',
          content: '+33 1 53 65 16 61',
          subContent: 'Support available 24/7'
        },
        email: {
          title: 'Email',
          content: 'support@1payt.com',
          subContent: 'Response within 24 hours'
        },
        hours: {
          title: 'Hours',
          content: 'Mon - Fri: 9am - 6pm',
          subContent: 'Paris Time (UTC+1)'
        }
      },
      form: {
        fullName: 'Full Name',
        email: 'Email',
        phone: 'Phone (optional)',
        subject: 'Subject',
        message: 'Message',
        file: 'Attach File',
        submit: 'Send Message',
        errors: {
          fullName: 'Full name is required',
          email: {
            required: 'Email is required',
            invalid: 'Invalid email'
          },
          subject: 'Subject is required',
          message: {
            required: 'Message is required',
            tooShort: 'Message must be at least 50 characters'
          },
          file: 'File must not exceed 5MB'
        }
      }
    },
    footer: {
      description: 'Universal crypto payment solution connecting fiat and digital currencies.',
      navigation: 'Navigation',
      legal: {
        title: 'Legal',
        privacy: 'Privacy Policy',
        terms: 'Terms of Service',
        notice: 'Legal Notice'
      },
      contact: 'Contact',
      address: 'Address',
      support: 'Support',
      rights: 'All rights reserved.'
    },
    blog: {
      title: 'Blog',
      categories: {
        all: 'All',
        technology: 'Technology',
        business: 'Business',
        security: 'Security'
      },
      search: 'Search articles...',
      filter: 'Filter by category',
      posts: {
        universal: {
          date: 'December 22, 2023',
          readTime: '5 min read',
          title: 'Universal Onramp Solution: The Future of Payments',
          excerpt: 'Discover how our solution is revolutionizing crypto payments.',
          author: '1PayT Team'
        },
        api: {
          date: 'December 20, 2023',
          readTime: '4 min read',
          title: 'API Integration Guide',
          excerpt: 'A comprehensive guide to integrating our payment API.',
          author: 'Technical Team'
        }
      }
    },
    legal: {
      title: 'Legal Notice',
      subtitle: 'Legal information about 1PayT',
      company: {
        title: 'Company Information',
        content: '1PayT is a simplified joint-stock company with a capital of 100,000 euros, registered in the Paris Trade and Companies Register.'
      },
      office: {
        title: 'Registered Office',
        address: '54 Avenue de la Motte Picquet\n75015 Paris\nFrance'
      },
      contact: {
        title: 'Contact',
        email: 'Email: contact@1payt.com',
        phone: 'Phone: +33 1 53 65 16 61'
      },
      hosting: {
        title: 'Hosting',
        content: 'The website 1payt.com is hosted by:\nHosting Company\nHost Address\nHost Contact'
      }
    },
    terms: {
      title: 'Terms of Service',
      subtitle: 'Rules governing the use of our services',
      sections: {
        acceptance: {
          title: '1. Acceptance of Terms',
          content: 'By using 1PayT services, you accept these terms of service.'
        },
        services: {
          title: '2. Services Offered',
          content: '1PayT offers a crypto-fiat payment solution including:',
          items: [
            'API integration',
            'Payment widget',
            'Technical support'
          ]
        },
        responsibilities: {
          title: '3. Responsibilities',
          content: 'Users commit to:',
          items: [
            'Provide accurate information',
            'Maintain account security',
            'Comply with applicable laws'
          ]
        }
      }
    },
    privacy: {
      title: 'Privacy Policy',
      subtitle: 'How we protect your data',
      sections: {
        collection: {
          title: '1. Data Collection',
          content: 'We only collect data necessary for our services:',
          items: [
            'Identification information (name, email)',
            'Transaction data',
            'Login information'
          ]
        },
        usage: {
          title: '2. Data Usage',
          content: 'Your data is used to:',
          items: [
            'Process your transactions',
            'Ensure account security',
            'Improve our services'
          ]
        },
        protection: {
          title: '3. Data Protection',
          content: 'We use advanced security measures to protect your data:',
          items: [
            'End-to-end encryption',
            'Multi-factor authentication',
            'Continuous system monitoring'
          ]
        }
      }
    }
  },
  fr: {
    nav: {
      features: 'Fonctionnalités',
      ecosystem: 'Écosystème',
      tokenIntegration: 'Intégration Token',
      faq: 'FAQ',
      support: 'Support'
    },
    hero: {
      title: 'Solution Universelle de Paiement',
      subtitle: 'Connectez-vous aux principales passerelles fiat mondialement via une API agrégateur unique et un widget.',
      cta: 'Commencer'
    },
    features: {
      title: 'Fonctionnalités Clés',
      subtitle: 'Tout ce dont vous avez besoin pour accepter les paiements crypto',
      api: {
        title: 'Solution Universal Onramp',
        description: 'Intégration API simplifiée pour une expérience utilisateur fluide'
      },
      ecosystem: {
        title: 'Écosystème 1PayT',
        description: 'Moteur Hybrid-Ramp pour des transactions optimisées'
      },
      onboarding: {
        title: 'Inscription Globale',
        description: 'Processus d\'inscription utilisateur simplifié'
      },
      tokens: {
        title: 'Intégration Token',
        description: 'Support pour plus de 9 000 tokens différents'
      },
      scalability: {
        title: 'Évolutivité Entreprise',
        description: 'Solutions évolutives pour entreprises de toutes tailles'
      },
      security: {
        title: 'Sécurité Prioritaire',
        description: 'Protection maximale pour vos transactions'
      }
    },
    ecosystem: {
      title: 'Écosystème Complet',
      subtitle: 'Une solution complète pour tous vos besoins en paiements crypto',
      wallet: 'Portefeuille Multi-Devises Sécurisé',
      exchange: 'Service d\'Échange Intégré',
      security: 'Fonctionnalités de Sécurité Avancées',
      speed: 'Transactions Ultra-Rapides'
    },
    tokenIntegration: {
      title: 'Intégration Token',
      subtitle: 'Support pour des milliers de tokens sur plusieurs blockchains',
      stats: {
        tokens: '9 000+ Tokens',
        networks: '15+ Réseaux',
        transactions: '1M+ Transactions/Jour',
        speed: '<2s Temps de Traitement'
      },
      integration: {
        title: 'Intégration Simple',
        subtitle: 'Commencez à accepter les paiements crypto en quelques lignes de code',
        codeComment: '// Exemple d\'intégration',
        codeExample: `const payment = await 1payt.createPayment({
  amount: 100,
  currency: 'EUR',
  tokens: ['ETH', 'USDT', 'BTC']
});`
      }
    },
    faq: {
      title: 'Questions Fréquentes',
      subtitle: 'Trouvez les réponses à vos questions sur 1PayT',
      questions: {
        integration: 'Comment intégrer 1PayT ?',
        fees: 'Quels sont les frais de transaction ?',
        countries: 'Quels pays sont supportés ?',
        security: 'La plateforme est-elle sécurisée ?',
        processing: 'Quel est le temps de traitement ?',
        testApi: 'Puis-je tester l\'API ?'
      },
      answers: {
        integration: 'L\'intégration est simple avec notre SDK et notre documentation complète.',
        fees: 'Nous proposons des frais compétitifs à partir de 0,5% par transaction.',
        countries: 'Nous supportons plus de 180 pays dans le monde.',
        security: 'Nous utilisons une sécurité de niveau bancaire avec protection multi-signature.',
        processing: 'La plupart des transactions sont traitées en moins de 2 secondes.',
        testApi: 'Oui, nous fournissons un environnement sandbox pour les tests.'
      },
      notFound: 'Vous n\'avez pas trouvé ce que vous cherchez ?',
      contact: 'Contactez notre équipe support'
    },
    support: {
      title: 'Support Client',
      subtitle: 'Notre équipe est là pour vous aider à chaque étape',
      channels: {
        chat: {
          title: 'Chat en Direct',
          description: 'Discutez avec notre équipe en temps réel'
        },
        email: {
          title: 'Support Email',
          description: 'Envoyez-nous un email pour une assistance détaillée'
        },
        docs: {
          title: 'Documentation',
          description: 'Consultez notre documentation complète'
        },
        dedicated: {
          title: 'Support Dédié',
          description: 'Support personnalisé pour les entreprises'
        }
      },
      contactButton: 'Contacter le Support'
    },
    supportPage: {
      title: 'Contactez Notre Support',
      subtitle: 'Notre équipe est là pour vous aider. Nous vous répondrons dans les plus brefs délais.',
      formTitle: 'Comment pouvons-nous vous aider ?',
      formDescription: 'Remplissez le formulaire ci-dessous avec les détails de votre demande. Notre équipe technique vous répondra sous 24 heures ouvrées.',
      info: {
        phone: {
          title: 'Téléphone',
          content: '+33 1 53 65 16 61',
          subContent: 'Support disponible 24/7'
        },
        email: {
          title: 'Email',
          content: 'support@1payt.com',
          subContent: 'Réponse sous 24 heures'
        },
        hours: {
          title: 'Horaires',
          content: 'Lun - Ven : 9h - 18h',
          subContent: 'Heure de Paris (UTC+1)'
        }
      },
      form: {
        fullName: 'Nom Complet',
        email: 'Email',
        phone: 'Téléphone (optionnel)',
        subject: 'Sujet',
        message: 'Message',
        file: 'Joindre un Fichier',
        submit: 'Envoyer le Message',
        errors: {
          fullName: 'Le nom complet est requis',
          email: {
            required: 'L\'email est requis',
            invalid: 'Email invalide'
          },
          subject: 'Le sujet est requis',
          message: {
            required: 'Le message est requis',
            tooShort: 'Le message doit contenir au moins 50 caractères'
          },
          file: 'Le fichier ne doit pas dépasser 5MB'
        }
      }
    },
    footer: {
      description: 'Solution de paiement crypto universelle reliant les monnaies fiduciaires et numériques.',
      navigation: 'Menu Principal',
      legal: {
        title: 'Mentions Légales',
        privacy: 'Politique de Confidentialité',
        terms: 'Conditions d\'Utilisation',
        notice: 'Mentions Légales'
      },
      contact: 'Nous Contacter',
      address: 'Adresse',
      support: 'Support',
      rights: 'Tous droits réservés.'
    },
    blog: {
      title: 'Blog',
      categories: {
        all: 'Tous',
        technology: 'Technologie',
        business: 'Entreprise',
        security: 'Sécurité'
      },
      search: 'Rechercher des articles...',
      filter: 'Filtrer par catégorie',
      posts: {
        universal: {
          date: '22 Décembre 2023',
          readTime: '5 min de lecture',
          title: 'Solution Universal Onramp: L\'avenir des paiements',
          excerpt: 'Découvrez comment notre solution révolutionne les paiements crypto.',
          author: 'Équipe 1PayT'
        },
        api: {
          date: '20 Décembre 2023',
          readTime: '4 min de lecture',
          title: 'Guide d\'intégration API',
          excerpt: 'Guide complet pour intégrer notre API de paiement.',
          author: 'Équipe Technique'
        }
      }
    },
    legal: {
      title: 'Mentions Légales',
      subtitle: 'Informations légales concernant 1PayT',
      company: {
        title: 'Informations Société',
        content: '1PayT est une société par actions simplifiée au capital de 100 000 euros, immatriculée au Registre du Commerce et des Sociétés de Paris.'
      },
      office: {
        title: 'Siège Social',
        address: '54 Avenue de la Motte Picquet\n75015 Paris\nFrance'
      },
      contact: {
        title: 'Contact',
        email: 'Email : contact@1payt.com',
        phone: 'Téléphone : +33 1 53 65 16 61'
      },
      hosting: {
        title: 'Hébergement',
        content: 'Le site 1payt.com est hébergé par :\nSociété d\'hébergement\nAdresse de l\'hébergeur\nContact hébergeur'
      }
    },
    terms: {
      title: 'Conditions d\'Utilisation',
      subtitle: 'Les règles qui régissent l\'utilisation de nos services',
      sections: {
        acceptance: {
          title: '1. Acceptation des Conditions',
          content: 'En utilisant les services de 1PayT, vous acceptez les présentes conditions d\'utilisation.'
        },
        services: {
          title: '2. Services Proposés',
          content: '1PayT propose une solution de paiement crypto-fiat incluant :',
          items: [
            'API d\'intégration',
            'Widget de paiement',
            'Support technique'
          ]
        },
        responsibilities: {
          title: '3. Responsabilités',
          content: 'Les utilisateurs s\'engagent à :',
          items: [
            'Fournir des informations exactes',
            'Maintenir la sécurité de leur compte',
            'Respecter les lois applicables'
          ]
        }
      }
    },
    privacy: {
      title: 'Politique de Confidentialité',
      subtitle: 'Comment nous protégeons vos données',
      sections: {
        collection: {
          title: '1. Collecte des Données',
          content: 'Nous collectons uniquement les données nécessaires au bon fonctionnement de nos services :',
          items: [
            'Informations d\'identification (nom, email)',
            'Données de transaction',
            'Informations de connexion'
          ]
        },
        usage: {
          title: '2. Utilisation des Données',
          content: 'Vos données sont utilisées pour :',
          items: [
            'Traiter vos transactions',
            'Assurer la sécurité de votre compte',
            'Améliorer nos services'
          ]
        },
        protection: {
          title: '3. Protection des Données',
          content: 'Nous utilisons des mesures de sécurité avancées pour protéger vos données :',
          items: [
            'Chiffrement de bout en bout',
            'Authentification multi-facteurs',
            'Surveillance continue des systèmes'
          ]
        }
      }
    }
  }
};

export const useTranslation = () => {
  const { language } = React.useContext(LanguageContext);
  
  const t = (key: string) => {
    const keys = key.split('.');
    let value: any = translations[language];
    
    for (const k of keys) {
      if (value === undefined) return key;
      value = value[k];
    }
    
    return value || key;
  };

  return { t, language };
};