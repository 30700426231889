import React from 'react';
import FeatureCard from './FeatureCard';
import { Code, Globe, Users, Coins, TrendingUp, Shield } from 'lucide-react';
import { useTranslation } from '../../utils/i18n';
import Container from '../shared/Container';

const Features = () => {
  const { t } = useTranslation();

  const features = [
    {
      title: t('features.api.title'),
      description: t('features.api.description'),
      Icon: Code,
    },
    {
      title: t('features.ecosystem.title'),
      description: t('features.ecosystem.description'),
      Icon: Globe,
    },
    {
      title: t('features.onboarding.title'),
      description: t('features.onboarding.description'),
      Icon: Users,
    },
    {
      title: t('features.tokens.title'),
      description: t('features.tokens.description'),
      Icon: Coins,
    },
    {
      title: t('features.scalability.title'),
      description: t('features.scalability.description'),
      Icon: TrendingUp,
    },
    {
      title: t('features.security.title'),
      description: t('features.security.description'),
      Icon: Shield,
    },
  ];

  return (
    <section id="features" className="py-12 sm:py-16 md:py-20 bg-gray-50">
      <Container>
        <div className="text-center">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-extrabold text-gray-900">
            {t('features.title')}
          </h2>
          <p className="mt-3 sm:mt-4 max-w-2xl mx-auto text-base sm:text-lg md:text-xl text-gray-500">
            {t('features.subtitle')}
          </p>
        </div>

        <div className="mt-10 sm:mt-12 md:mt-16">
          <div className="grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                Icon={feature.Icon}
                className="h-full"
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Features;