import React from 'react';
import { useNavigate } from 'react-router-dom';
import FAQItem from './FAQItem';
import { useTranslation } from '../../utils/i18n';

const FAQ = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const faqItems = [
    {
      question: t('faq.questions.integration'),
      answer: t('faq.answers.integration'),
    },
    {
      question: t('faq.questions.fees'),
      answer: t('faq.answers.fees'),
    },
    {
      question: t('faq.questions.countries'),
      answer: t('faq.answers.countries'),
    },
    {
      question: t('faq.questions.security'),
      answer: t('faq.answers.security'),
    },
    {
      question: t('faq.questions.processing'),
      answer: t('faq.answers.processing'),
    },
    {
      question: t('faq.questions.testApi'),
      answer: t('faq.answers.testApi'),
    },
  ];

  const handleSupportClick = () => {
    navigate('/support');
  };

  return (
    <section id="faq" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {t('faq.title')}
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            {t('faq.subtitle')}
          </p>
        </div>

        <div className="mt-12 max-w-3xl mx-auto">
          {faqItems.map((item, index) => (
            <FAQItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>

        <div className="mt-16 text-center">
          <p className="text-base text-gray-500">
            {t('faq.notFound')}
          </p>
          <button
            onClick={handleSupportClick}
            className="mt-4 inline-flex items-center text-base font-medium text-primary hover:text-primary-dark transition-colors"
          >
            {t('faq.contact')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default FAQ;