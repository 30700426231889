import React from 'react';
import { useScrollToTop } from '../hooks/useScrollToTop';
import Container from '../components/shared/Container';
import BlogList from '../components/Blog/BlogList';
import { useTranslation } from '../utils/i18n';
import { motion } from 'framer-motion';

const Blog = () => {
  const { t } = useTranslation();
  useScrollToTop();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="pt-20 pb-12 bg-primary/5">
        <Container>
          <motion.div
            className="max-w-3xl mx-auto text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 mb-4">
              {t('blog.pageTitle')}
            </h1>
            <p className="text-lg text-gray-600">
              {t('blog.pageDescription')}
            </p>
          </motion.div>
        </Container>
      </div>
      
      <Container className="py-12">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <BlogList />
        </motion.div>
      </Container>
    </div>
  );
};

export default Blog;
