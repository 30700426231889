import React from 'react';
import { Github, Twitter, Linkedin, Mail, Phone } from 'lucide-react';
import { NAV_ITEMS } from '../../constants/navigation';
import { SocialLink } from '../../types';
import Logo from '../shared/Logo';
import Container from '../shared/Container';
import { useTranslation } from '../../utils/i18n';
import { CONFIG } from '../../constants/config';

const Footer = () => {
  const { t } = useTranslation();

  const socialLinks: SocialLink[] = [
    { name: 'GitHub', href: 'https://github.com/1payt', Icon: Github },
    { name: 'Twitter', href: 'https://twitter.com/1payt', Icon: Twitter },
    { name: 'LinkedIn', href: 'https://linkedin.com/company/1payt', Icon: Linkedin },
    { name: 'Email', href: 'mailto:contact@1payt.com', Icon: Mail },
  ];

  const legalLinks = [
    { label: t('footer.legal.privacy'), href: '/privacy' },
    { label: t('footer.legal.terms'), href: '/terms' },
    { label: t('footer.legal.notice'), href: '/legal' },
  ];

  const navItems = [
    { label: t('nav.features'), href: '#features' },
    { label: t('nav.ecosystem'), href: '#ecosystem' },
    { label: t('nav.tokenIntegration'), href: '#token' },
    { label: t('nav.support'), href: '#support' },
    { label: t('nav.faq'), href: '#faq' },
  ];

  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 pt-16 pb-8">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Brand Section */}
          <div className="space-y-6">
            <Logo className="text-white" />
            <p className="text-gray-400 text-sm leading-relaxed">
              {t('footer.description')}
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-primary transition-colors duration-200"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.Icon className="h-5 w-5" />
                </a>
              ))}
            </div>
          </div>

          {/* Navigation Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">
              {t('footer.navigation')}
            </h3>
            <ul className="space-y-3">
              {navItems.map((item) => (
                <li key={item.label}>
                  <a
                    href={item.href}
                    className="text-gray-400 hover:text-primary transition-colors duration-200 text-sm"
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Legal Links */}
          <div>
            <h3 className="text-white font-semibold mb-4">
              {t('footer.legal.title')}
            </h3>
            <ul className="space-y-3">
              {legalLinks.map((item) => (
                <li key={item.label}>
                  <a
                    href={item.href}
                    className="text-gray-400 hover:text-primary transition-colors duration-200 text-sm"
                  >
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h3 className="text-white font-semibold mb-4">
              {t('footer.contact')}
            </h3>
            <ul className="space-y-3 text-sm">
              <li className="text-gray-400">
                <strong className="text-white">{t('footer.address')}:</strong><br />
                {CONFIG.contact.address.street}<br />
                {CONFIG.contact.address.zip} {CONFIG.contact.address.city}, {CONFIG.contact.address.country}
              </li>
              <li className="text-gray-400 flex items-center space-x-2">
                <Phone className="h-4 w-4" />
                <a
                  href={`tel:${CONFIG.contact.phone}`}
                  className="hover:text-primary transition-colors duration-200"
                >
                  {CONFIG.contact.phone}
                </a>
              </li>
              <li className="flex items-center space-x-2">
                <Mail className="h-4 w-4 text-gray-400" />
                <a
                  href={`mailto:${CONFIG.contact.email}`}
                  className="text-gray-400 hover:text-primary transition-colors duration-200"
                >
                  {CONFIG.contact.email}
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="mt-12 pt-8 border-t border-gray-800">
          <p className="text-gray-400 text-sm text-center">
            &copy; {currentYear} 1PayT. {t('footer.rights')}
          </p>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;