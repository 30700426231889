import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../shared/Logo';
import Button from '../shared/Button';
import Container from '../shared/Container';
import NavLink from '../shared/NavLink';
import LanguageToggle from '../shared/LanguageToggle';
import { useTranslation } from '../../utils/i18n';
import { cn } from '../../utils/cn';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const NAV_ITEMS = [
    { label: t('nav.features'), href: '#features' },
    { label: t('nav.ecosystem'), href: '#ecosystem' },
    { label: t('nav.tokenIntegration'), href: '#token' },
    { label: t('nav.faq'), href: '#faq' },
  ];

  const handleNavClick = (href: string) => {
    setIsMenuOpen(false);
    if (location.pathname !== '/') {
      navigate('/' + href);
    } else {
      const element = document.querySelector(href);
      if (element) {
        const navHeight = 80; // Height of your fixed header
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navHeight;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  const handleSupportClick = () => {
    navigate('/support');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    setIsMenuOpen(false);
  };

  return (
    <header className={cn(
      'fixed top-0 w-full z-50 bg-white/95 backdrop-blur-md shadow-sm',
      'transition-all duration-300'
    )}>
      <Container>
        <nav className="flex items-center justify-between h-20 relative">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className="flex-shrink-0"
          >
            <Logo />
          </motion.div>

          <div className="hidden md:flex items-center space-x-8">
            {NAV_ITEMS.map((item, index) => (
              <motion.div
                key={item.label}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <NavLink
                  href={item.href}
                  onClick={() => handleNavClick(item.href)}
                >
                  {item.label}
                </NavLink>
              </motion.div>
            ))}

            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: NAV_ITEMS.length * 0.1 }}
            >
              <Button onClick={handleSupportClick}>
                {t('nav.support')}
              </Button>
            </motion.div>

            <LanguageToggle />
          </div>

          <div className="flex items-center md:hidden">
            <LanguageToggle />
            <button
              className="p-2 ml-2"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              aria-label="Toggle menu"
            >
              <div className="w-6 h-5 flex flex-col justify-between">
                <span className={cn(
                  'w-full h-0.5 bg-gray-600 transition-all duration-300 origin-center',
                  isMenuOpen && 'transform rotate-45 translate-y-[9px]'
                )} />
                <span className={cn(
                  'w-full h-0.5 bg-gray-600 transition-all duration-300',
                  isMenuOpen && 'opacity-0 transform scale-x-0'
                )} />
                <span className={cn(
                  'w-full h-0.5 bg-gray-600 transition-all duration-300 origin-center',
                  isMenuOpen && 'transform -rotate-45 -translate-y-[9px]'
                )} />
              </div>
            </button>
          </div>

          <div className={cn(
            'absolute top-full left-0 right-0 w-full bg-white shadow-lg md:hidden',
            'transition-all duration-300 transform',
            isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'
          )}>
            <div className="p-4 flex flex-col space-y-3">
              {NAV_ITEMS.map((item) => (
                <NavLink
                  key={item.label}
                  href={item.href}
                  onClick={() => handleNavClick(item.href)}
                  className="block w-full py-2 px-4 hover:bg-gray-50 rounded-md transition-colors text-gray-700"
                >
                  {item.label}
                </NavLink>
              ))}
              <div className="pt-2">
                <Button
                  onClick={handleSupportClick}
                  className="w-full justify-center"
                  variant="primary"
                >
                  {t('nav.support')}
                </Button>
              </div>
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
};

export default Header;