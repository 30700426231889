import React from 'react';
import { cn } from '../../utils/cn';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

const NavLink = ({ href, children, onClick, className }: NavLinkProps) => {
  return (
    <a
      href={href}
      onClick={onClick}
      className={cn(
        "text-base font-medium text-gray-500 hover:text-gray-900 transition-colors duration-200",
        className
      )}
    >
      {children}
    </a>
  );
};

export default NavLink;