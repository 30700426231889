import React from 'react';
import { useNavigate } from 'react-router-dom';
import SupportCard from './SupportCard';
import { MessageCircle, Mail, BookOpen, Clock } from 'lucide-react';
import { useTranslation } from '../../utils/i18n';
import Button from '../shared/Button';

const Support = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const supportChannels = [
    {
      title: t('support.channels.chat.title'),
      description: t('support.channels.chat.description'),
      Icon: MessageCircle,
    },
    {
      title: t('support.channels.email.title'),
      description: t('support.channels.email.description'),
      Icon: Mail,
    },
    {
      title: t('support.channels.docs.title'),
      description: t('support.channels.docs.description'),
      Icon: BookOpen,
    },
    {
      title: t('support.channels.dedicated.title'),
      description: t('support.channels.dedicated.description'),
      Icon: Clock,
    },
  ];

  const handleContactSupport = () => {
    navigate('/support');
  };

  return (
    <section id="support" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {t('support.title')}
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            {t('support.subtitle')}
          </p>
        </div>

        <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {supportChannels.map((channel) => (
            <SupportCard
              key={channel.title}
              title={channel.title}
              description={channel.description}
              Icon={channel.Icon}
            />
          ))}
        </div>

        <div className="mt-12 text-center">
          <Button onClick={handleContactSupport} variant="primary">
            {t('support.contactButton')}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Support;