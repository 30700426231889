import React from 'react';
import EcosystemCard from './EcosystemCard';
import { Wallet, ArrowLeftRight, Shield, Zap } from 'lucide-react';
import { useTranslation } from '../../utils/i18n';

const Ecosystem = () => {
  const { t } = useTranslation();

  const ecosystemFeatures = [
    {
      title: t('ecosystem.wallet'),
      description: t('ecosystem.wallet'),
      Icon: Wallet,
    },
    {
      title: t('ecosystem.exchange'),
      description: t('ecosystem.exchange'),
      Icon: ArrowLeftRight,
    },
    {
      title: t('ecosystem.security'),
      description: t('ecosystem.security'),
      Icon: Shield,
    },
    {
      title: t('ecosystem.speed'),
      description: t('ecosystem.speed'),
      Icon: Zap,
    },
  ];

  return (
    <section id="ecosystem" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {t('ecosystem.title')}
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            {t('ecosystem.subtitle')}
          </p>
        </div>

        <div className="mt-16">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2">
            {ecosystemFeatures.map((feature) => (
              <EcosystemCard
                key={feature.title}
                title={feature.title}
                description={feature.description}
                Icon={feature.Icon}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ecosystem;