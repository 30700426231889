import React from 'react';
import { cn } from '../../utils/cn';
import Container from './Container';
import { motion } from 'framer-motion';

interface SectionProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  background?: 'white' | 'gray';
  animate?: boolean;
}

const Section = ({
  id,
  className,
  children,
  background = 'white',
  animate = true
}: SectionProps) => {
  const content = (
    <section
      id={id}
      className={cn(
        'py-20',
        background === 'gray' ? 'bg-gray-50' : 'bg-white',
        className
      )}
    >
      <Container>{children}</Container>
    </section>
  );

  if (!animate) return content;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ duration: 0.5 }}
    >
      {content}
    </motion.div>
  );
};

export default Section;