import React from 'react';
import { LucideIcon } from 'lucide-react';

interface SupportCardProps {
  title: string;
  description: string;
  Icon: LucideIcon;
}

const SupportCard = ({ title, description, Icon }: SupportCardProps) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <div className="flex items-center justify-center w-12 h-12 bg-indigo-100 rounded-full mb-4 mx-auto">
        <Icon className="h-6 w-6 text-indigo-600" />
      </div>
      <h3 className="text-lg font-medium text-gray-900 text-center mb-2">{title}</h3>
      <p className="text-gray-500 text-center">{description}</p>
    </div>
  );
};

export default SupportCard;