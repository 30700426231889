import React from 'react';
import { LucideIcon } from 'lucide-react';

interface EcosystemCardProps {
  title: string;
  description: string;
  Icon: LucideIcon;
  iconBgColor?: string;
  iconColor?: string;
}

const EcosystemCard = ({
  title,
  description,
  Icon,
  iconBgColor = 'bg-indigo-100',
  iconColor = 'text-indigo-600'
}: EcosystemCardProps) => {
  return (
    <div className="flex items-start space-x-4">
      <div className={`${iconBgColor} p-3 rounded-lg`}>
        <Icon className={`h-6 w-6 ${iconColor}`} />
      </div>
      <div>
        <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        <p className="mt-2 text-base text-gray-500">{description}</p>
      </div>
    </div>
  );
};

export default EcosystemCard;