import React from 'react';
import Section from '../components/shared/Section';
import SectionTitle from '../components/shared/SectionTitle';
import Container from '../components/shared/Container';
import { useTranslation } from '../utils/i18n';
import PDFViewer from '../components/shared/PDFViewer';
import pdfFile from '../assets/1PayT english.pdf';

const Terms = () => {
  // Using exact text from the PDF
  return <PDFViewer pdfPath={pdfFile} sectionToScroll="III.General Conditions of Use (CGU)" />;
};

export default Terms;