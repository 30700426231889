import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTranslation } from '../../utils/i18n';
import Container from '../shared/Container';
import BlogCard from './BlogCard';

const BlogPreview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const blogPosts = [
    {
      id: 'universal',
      image: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80&w=800',
      date: t('blog.posts.universal.date'),
      readTime: t('blog.posts.universal.readTime'),
      title: t('blog.posts.universal.title'),
      excerpt: t('blog.posts.universal.excerpt'),
      author: t('blog.posts.universal.author'),
      slug: '/blog/universal-onramp'
    },
    {
      id: 'api',
      image: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?auto=format&fit=crop&q=80&w=800',
      date: t('blog.posts.api.date'),
      readTime: t('blog.posts.api.readTime'),
      title: t('blog.posts.api.title'),
      excerpt: t('blog.posts.api.excerpt'),
      author: t('blog.posts.api.author'),
      slug: '/blog/api-integration'
    },
    {
      id: 'security',
      image: 'https://images.unsplash.com/photo-1639762681057-408e52192e55?auto=format&fit=crop&q=80&w=800',
      date: t('blog.posts.security.date'),
      readTime: t('blog.posts.security.readTime'),
      title: t('blog.posts.security.title'),
      excerpt: t('blog.posts.security.excerpt'),
      author: t('blog.posts.security.author'),
      slug: '/blog/security'
    }
  ];

  const handleViewAllClick = () => {
    navigate('/blog');
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <section className="py-12 sm:py-16 md:py-20 bg-gray-50">
      <Container>
        {/* Section Header */}
        <motion.div
          className="text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
        >
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            {t('blog.title')}
          </h2>
          <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
            {t('blog.subtitle')}
          </p>
        </motion.div>

        {/* Blog Cards Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 mb-12">
          {blogPosts.map((post, index) => (
            <BlogCard
              key={post.id}
              {...post}
            />
          ))}
        </div>

        {/* View All Button */}
        <motion.div
          className="text-center"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <button
            onClick={handleViewAllClick}
            className="inline-flex items-center px-6 py-3 text-base font-medium text-white bg-primary rounded-lg hover:bg-primary/90 transition-colors group"
          >
            View All Posts
            <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </button>
        </motion.div>
      </Container>
    </section>
  );
};

export default BlogPreview;