import React from 'react';
import { LucideIcon } from 'lucide-react';
import { cn } from '../../utils/cn';

interface FeatureCardProps {
  title: string;
  description: string;
  Icon: LucideIcon;
  className?: string;
}

const FeatureCard = ({ title, description, Icon, className }: FeatureCardProps) => {
  return (
    <div className={cn(
      'relative p-6 sm:p-8 bg-white rounded-2xl shadow-sm hover:shadow-md transition-shadow',
      'border border-gray-100 hover:border-gray-200',
      'flex flex-col',
      className
    )}>
      <div className="flex items-center space-x-4 sm:space-x-6">
        <div className="flex-shrink-0">
          <div className="p-2 sm:p-3 bg-primary/10 rounded-xl">
            <Icon className="w-6 h-6 sm:w-7 sm:h-7 text-primary" />
          </div>
        </div>
        <h3 className="text-lg sm:text-xl font-semibold text-gray-900">
          {title}
        </h3>
      </div>
      <p className="mt-4 text-base sm:text-lg text-gray-500 flex-grow">
        {description}
      </p>
    </div>
  );
};

export default FeatureCard;