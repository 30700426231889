import React from 'react';
import { Globe } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTranslation } from '../../utils/i18n';
import { LanguageContext } from '../../utils/i18n';
import { cn } from '../../utils/cn';

const LanguageToggle = () => {
  const { language } = useTranslation();
  const { setLanguage } = React.useContext(LanguageContext);

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'fr' : 'en');
  };

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={toggleLanguage}
      className={cn(
        'flex items-center space-x-2 px-3 py-2 rounded-md',
        'text-gray-600 hover:text-primary hover:bg-primary/5',
        'transition-colors duration-200'
      )}
      aria-label={`Change language to ${language === 'en' ? 'French' : 'English'}`}
    >
      <Globe className="h-4 w-4" />
      <span className="text-sm font-medium uppercase">{language}</span>
    </motion.button>
  );
};

export default LanguageToggle;