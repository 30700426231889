import { useEffect } from 'react';
import { MessageCircle } from 'lucide-react';
import Button from '../shared/Button';
import { initCrispChat, openCrispChat } from '../../utils/crisp';
import { cn } from '../../utils/cn';

interface LiveChatProps {
  className?: string;
}

const LiveChat = ({ className }: LiveChatProps) => {
  useEffect(() => {
    // Initialize Crisp chat when component mounts
    initCrispChat();
  }, []);

  return (
    <Button
      variant="primary"
      className={cn(
        'flex h-12 w-12 items-center justify-center rounded-full p-0 shadow-lg hover:shadow-xl',
        className
      )}
      onClick={openCrispChat}
      icon={<MessageCircle size={24} />}
    />
  );
};

export default LiveChat;
