import { useEffect, useRef, useState } from 'react';
import Container from './Container';
import { findPageNumber } from '../../utils/pdfUtils';

interface PDFViewerProps {
  pdfPath: string;
  sectionToScroll?: string;
}

const PDFViewer = ({ pdfPath, sectionToScroll }: PDFViewerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [pageNumber, setPageNumber] = useState<number | null>(null);

  useEffect(() => {
    // Adjust iframe height to viewport
    const updateHeight = () => {
      if (containerRef.current) {
        const windowHeight = window.innerHeight;
        const headerHeight = 80; // Height of your header
        containerRef.current.style.height = `${windowHeight - headerHeight}px`;
      }
    };

    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  useEffect(() => {
    if (sectionToScroll) {
      findPageNumber(pdfPath, sectionToScroll).then(page => {
        setPageNumber(page);
      });
    }
  }, [sectionToScroll, pdfPath]);

  // Construct the URL with page number if found
  const viewerUrl = pageNumber 
    ? `${pdfPath}#page=${pageNumber}`
    : pdfPath;

  return (
    <Container>
      <div ref={containerRef} className="w-full">
        <iframe
          src={viewerUrl}
          className="h-full w-full"
          title="PDF Document"
        />
      </div>
    </Container>
  );
};

export default PDFViewer;
