import React from 'react';
import TokenStats from './TokenStats';
import { Coins } from 'lucide-react';
import { useTranslation } from '../../utils/i18n';

const TokenIntegration = () => {
  const { t } = useTranslation();

  return (
    <section id="token" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <div className="flex items-center justify-center mb-4">
            <Coins className="h-12 w-12 text-indigo-600" />
          </div>
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            {t('tokenIntegration.title')}
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            {t('tokenIntegration.subtitle')}
          </p>
        </div>

        <div className="mt-16">
          <TokenStats />
          
          <div className="mt-12 bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="px-6 py-8">
              <div className="text-center">
                <h3 className="text-lg font-medium text-gray-900">
                  {t('tokenIntegration.integration.title')}
                </h3>
                <p className="mt-2 text-base text-gray-500">
                  {t('tokenIntegration.integration.subtitle')}
                </p>
              </div>
              
              <div className="mt-8">
                <div className="bg-gray-50 rounded-lg p-6">
                  <pre className="text-sm text-gray-700 overflow-x-auto">
                    <code>
                      {t('tokenIntegration.integration.codeComment')}
                      {'\n'}
                      {t('tokenIntegration.integration.codeExample')}
                    </code>
                  </pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TokenIntegration;