import React from 'react';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import Button from '../shared/Button';
import Container from '../shared/Container';
import { useTranslation } from '../../utils/i18n';

const Hero = () => {
  const { t } = useTranslation();

  const handleGetStarted = () => {
    window.location.href = 'https://app.1payt.com/sign-up';
  };

  return (
    <section className="relative pt-4 md:pt-8 pb-12 md:pb-16 overflow-hidden">
      <Container className="px-0">
        <div className="flex flex-col lg:flex-row items-center justify-between min-h-[calc(100vh-80px)]">
          {/* Text Content */}
          <motion.div 
            className="text-center lg:text-left px-4 sm:px-6 lg:pl-12 lg:pr-16 w-full lg:w-[45%] pt-8 lg:pt-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
              className="text-3xl sm:text-4xl md:text-5xl xl:text-6xl tracking-tight font-extrabold"
            >
              <span className="block text-gray-900">Universal Onramp</span>
              <span className="block text-gray-900">Solution</span>
            </motion.h1>
            
            <motion.p 
              className="mt-4 md:mt-6 text-base sm:text-lg md:text-xl text-gray-500 max-w-lg"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              {t('hero.subtitle')}
            </motion.p>

            <motion.div 
              className="mt-6 md:mt-8 flex justify-center lg:justify-start space-x-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <Button 
                size="lg" 
                className="group shadow-lg hover:shadow-xl transform hover:-translate-y-0.5 transition-all duration-200 w-full sm:w-auto"
                icon={<ArrowRight className="h-5 w-5 group-hover:translate-x-1 transition-transform" />}
                onClick={handleGetStarted}
              >
                {t('hero.cta')}
              </Button>
            </motion.div>
          </motion.div>

          {/* Widget */}
          <motion.div
            className="mt-8 lg:mt-0 px-4 sm:px-6 lg:px-8 w-full lg:w-[55%]"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <div className="relative w-full max-w-[400px] sm:max-w-[450px] mx-auto">
              <iframe
                src="https://ramping-engine.1payt.com/?api_key=pk_prod_jqB4CP1lXOWdBu2rbhHxc0A84ISDJQ81"
                className="w-full h-[600px] sm:h-[580px] rounded-lg border-0 shadow-lg bg-white"
                title="1PayT Widget"
                allow="camera; microphone; payment"
              />
            </div>
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default Hero;