import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LanguageContext, Language } from './utils/i18n';
import Header from './components/Header/Header';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Support from './pages/Support';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Legal from './pages/Legal';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/shared/ScrollToTop';
import ScrollProgress from './components/shared/ScrollProgress';
import ScrollToHashElement from './components/shared/ScrollToHashElement';
import ErrorBoundary from './components/shared/ErrorBoundary';
import LiveChat from './components/Support/LiveChat';

function App() {
  const [language, setLanguage] = useState<Language>('en');

  return (
    <ErrorBoundary>
      <LanguageContext.Provider value={{ language, setLanguage }}>
        <Router>
          <div className="min-h-screen bg-white">
            <ScrollProgress />
            <ScrollToHashElement />
            <Header />
            <main className="pt-20">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/blog/*" element={<Blog />} />
                <Route path="/support" element={<Support />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/legal" element={<Legal />} />
              </Routes>
            </main>
            <Footer />
            <ScrollToTop />
            <div className="fixed bottom-6 right-6 z-50">
              <LiveChat />
            </div>
          </div>
        </Router>
      </LanguageContext.Provider>
    </ErrorBoundary>
  );
}

export default App;