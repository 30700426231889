import React, { useState, useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BlogCard from './BlogCard';
import { useTranslation } from '../../utils/i18n';
import { Search, Filter } from 'lucide-react';

type Category = 'all' | 'technology' | 'business' | 'security';

const BlogList = () => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<Category>('all');

  // This would typically come from an API or CMS
  const blogPosts = [
    {
      id: 'universal',
      image: 'https://images.unsplash.com/photo-1639762681485-074b7f938ba0?auto=format&fit=crop&q=80&w=800',
      date: t('blog.posts.universal.date'),
      readTime: t('blog.posts.universal.readTime'),
      title: t('blog.posts.universal.title'),
      excerpt: t('blog.posts.universal.excerpt'),
      author: t('blog.posts.universal.author'),
      slug: '/blog/universal-onramp',
      category: 'technology'
    },
    {
      id: 'api',
      image: 'https://images.unsplash.com/photo-1633356122544-f134324a6cee?auto=format&fit=crop&q=80&w=800',
      date: t('blog.posts.api.date'),
      readTime: t('blog.posts.api.readTime'),
      title: t('blog.posts.api.title'),
      excerpt: t('blog.posts.api.excerpt'),
      author: t('blog.posts.api.author'),
      slug: '/blog/api-integration',
      category: 'technology'
    },
    {
      id: 'security',
      image: 'https://images.unsplash.com/photo-1639762681057-408e52192e55?auto=format&fit=crop&q=80&w=800',
      date: t('blog.posts.security.date'),
      readTime: t('blog.posts.security.readTime'),
      title: t('blog.posts.security.title'),
      excerpt: t('blog.posts.security.excerpt'),
      author: t('blog.posts.security.author'),
      slug: '/blog/security',
      category: 'security'
    },
  ];

  const categories: { value: Category; label: string }[] = [
    { value: 'all', label: 'All Posts' },
    { value: 'technology', label: 'Technology' },
    { value: 'business', label: 'Business' },
    { value: 'security', label: 'Security' },
  ];

  const filteredPosts = useMemo(() => {
    return blogPosts.filter(post => {
      const matchesSearch = post.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          post.excerpt.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || post.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }, [blogPosts, searchQuery, selectedCategory]);

  return (
    <div className="space-y-8">
      {/* Search and Filter Section */}
      <div className="flex flex-col sm:flex-row gap-4 items-center justify-between mb-8">
        <div className="relative w-full sm:w-96">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search posts..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary/20 focus:border-primary"
          />
        </div>

        <div className="flex items-center space-x-2 w-full sm:w-auto">
          <Filter className="text-gray-400 h-5 w-5" />
          <div className="flex gap-2 overflow-x-auto pb-2 w-full sm:w-auto">
            {categories.map((category) => (
              <button
                key={category.value}
                onClick={() => setSelectedCategory(category.value)}
                className={`px-4 py-2 rounded-lg text-sm font-medium whitespace-nowrap transition-colors
                  ${selectedCategory === category.value
                    ? 'bg-primary text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
              >
                {category.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Blog Posts Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
        <AnimatePresence mode="popLayout">
          {filteredPosts.map((post, index) => (
            <motion.div
              key={post.id}
              layout
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              <BlogCard {...post} />
            </motion.div>
          ))}
        </AnimatePresence>
        
        {filteredPosts.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="col-span-full text-center py-12"
          >
            <p className="text-gray-500 text-lg">No posts found matching your criteria.</p>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default BlogList;
