export const CONFIG = {
  api: {
    baseUrl: 'https://api.1payt.com',
    version: 'v1',
  },
  app: {
    name: '1PayT',
    description: 'Universal Onramp Solution',
  },
  contact: {
    email: 'contact@1payt.com',
    support: 'support@1payt.com',
    phone: '+33 1 53 65 16 61',
    address: {
      street: '54 Avenue de la Motte Picquet',
      city: 'Paris',
      zip: '75015',
      country: 'France',
    },
  },
  social: {
    twitter: 'https://twitter.com/1payt',
    github: 'https://github.com/1payt',
    linkedin: 'https://linkedin.com/company/1payt',
  },
} as const;