import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToHashElement = () => {
  const location = useLocation();

  const scrollToElement = useCallback((hash: string) => {
    const element = document.querySelector(hash);
    if (element) {
      const navHeight = 80; // Height of your fixed header
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, []);

  useEffect(() => {
    if (location.hash) {
      // Slight delay to ensure DOM is ready
      setTimeout(() => {
        scrollToElement(location.hash);
      }, 0);
    }
  }, [location.hash, scrollToElement]);

  return null;
};

export default ScrollToHashElement;
