import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from '../../utils/i18n';

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string;
}

const Meta = ({
  title,
  description,
  image = 'https://www.1payt.com/assets/images/og-preview.jpg',
  url = 'https://www.1payt.com',
  type = 'website'
}: MetaProps) => {
  const { language } = useTranslation();
  const baseUrl = 'https://www.1payt.com';

  const defaultTitle = language === 'en' 
    ? '1PayT - Universal Onramp Solution | Crypto Payment Gateway'
    : '1PayT - Solution Universal Onramp | Passerelle de Paiement Crypto';

  const defaultDescription = language === 'en'
    ? 'Connect to major fiat gateways globally through a single aggregator API and widget. Fast, secure, and universal crypto payment solution.'
    : 'Connectez-vous aux principales passerelles fiat mondialement via une API agrégateur unique et un widget. Solution de paiement crypto rapide, sécurisée et universelle.';

  const pageTitle = title ? `${title} | 1PayT` : defaultTitle;
  const pageDescription = description || defaultDescription;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <html lang={language} />
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={image} />
      <meta property="og:locale" content={language === 'en' ? 'en_US' : 'fr_FR'} />
      <meta property="og:locale:alternate" content={language === 'en' ? 'fr_FR' : 'en_US'} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={image} />

      {/* Alternate Language Links */}
      <link rel="alternate" hreflang="en" href={`${baseUrl}/en${url.replace(baseUrl, '')}`} />
      <link rel="alternate" hreflang="fr" href={`${baseUrl}/fr${url.replace(baseUrl, '')}`} />
      <link rel="alternate" hreflang="x-default" href={baseUrl} />

      {/* Canonical URL */}
      <link rel="canonical" href={url} />

      {/* JSON-LD Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "1PayT",
          "url": baseUrl,
          "logo": `${baseUrl}/assets/images/logo.png`,
          "sameAs": [
            "https://twitter.com/1payt",
            "https://linkedin.com/company/1payt"
          ],
          "description": defaultDescription
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "1PayT",
          "url": baseUrl,
          "potentialAction": {
            "@type": "SearchAction",
            "target": `${baseUrl}/search?q={search_term_string}`,
            "query-input": "required name=search_term_string"
          }
        })}
      </script>
    </Helmet>
  );
};

export default Meta;