import React from 'react';
import { useTranslation } from '../../utils/i18n';

interface StatProps {
  label: string;
  value: string;
}

const Stat = ({ label, value }: StatProps) => (
  <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
    <dt className="text-sm font-medium text-gray-500 truncate">{label}</dt>
    <dd className="mt-1 text-3xl font-semibold text-indigo-600">{value}</dd>
  </div>
);

const TokenStats = () => {
  const { t } = useTranslation();
  
  const stats = [
    { label: t('tokenIntegration.stats.tokens'), value: '9,000+' },
    { label: t('tokenIntegration.stats.networks'), value: '15+' },
    { label: t('tokenIntegration.stats.transactions'), value: '1M+' },
    { label: t('tokenIntegration.stats.speed'), value: '<2s' },
  ];

  return (
    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => (
        <Stat key={stat.label} {...stat} />
      ))}
    </dl>
  );
};

export default TokenStats;