import React, { useEffect } from 'react';
import { MessageCircle } from 'lucide-react';
import Container from '../components/shared/Container';
import Section from '../components/shared/Section';
import SectionTitle from '../components/shared/SectionTitle';
import ContactForm from '../components/Support/ContactForm';
import ContactInfo from '../components/Support/ContactInfo';

const Support = () => {
  return (
    <>
      <Section className="bg-gradient-to-b from-white to-gray-50/50">
        <Container>
          <SectionTitle
            title="We're Here to Help"
            description="Get in touch with our support team through your preferred communication channel."
            icon={<MessageCircle className="text-primary" size={32} />}
          />
          
          <div className="mt-12">
            <ContactInfo />
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="mx-auto max-w-2xl">
            <SectionTitle
              title="Send Us a Message"
              description="Fill out the form below and we'll get back to you as soon as possible."
              className="text-center"
            />
            <div className="mt-8">
              <ContactForm />
            </div>
          </div>
        </Container>
      </Section>
    </>
  );
};

export default Support;