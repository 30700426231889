declare global {
  interface Window {
    $crisp: any[];
    CRISP_WEBSITE_ID: string;
  }
}

export const initCrispChat = () => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = "90d71bf1-77de-4b62-9200-ef4eab1b9d1f"; //Crisp Website ID

  // Load Crisp script
  (function() {
    const d = document;
    const s = d.createElement("script");
    s.src = "https://client.crisp.chat/l.js";
    s.async = true;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
};

export const openCrispChat = () => {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:open"]);
  }
};

export const closeCrispChat = () => {
  if (window.$crisp) {
    window.$crisp.push(["do", "chat:close"]);
  }
};

export const sendCrispMessage = (message: string) => {
  if (window.$crisp) {
    window.$crisp.push(["do", "message:send", ["text", message]]);
  }
};
