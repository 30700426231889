import React from 'react';
import Meta from '../components/SEO/Meta';
import Hero from '../components/Hero/Hero';
import Features from '../components/Features/Features';
import Ecosystem from '../components/Ecosystem/Ecosystem';
import TokenIntegration from '../components/TokenIntegration/TokenIntegration';
import Support from '../components/Support/Support';
import FAQ from '../components/FAQ/FAQ';
import BlogPreview from '../components/Blog/BlogPreview';
import { useTranslation } from '../utils/i18n';

const Home = () => {
  const { t, language } = useTranslation();

  return (
    <>
      <Meta
        title={t('hero.title')}
        description={t('hero.subtitle')}
        url={`https://www.1payt.com/${language}`}
      />
      <Hero />
      <Features />
      <Ecosystem />
      <TokenIntegration />
      <BlogPreview />
      <Support />
      <FAQ />
    </>
  );
};

export default Home;