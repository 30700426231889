import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Clock, Calendar, ChevronRight, User } from 'lucide-react';
import { cn } from '../../utils/cn';

interface BlogCardProps {
  image: string;
  date: string;
  readTime: string;
  title: string;
  excerpt: string;
  author: string;
  slug: string;
  className?: string;
}

const BlogCard = ({ image, date, readTime, title, excerpt, author, slug, className }: BlogCardProps) => {
  return (
    <motion.div
      className={cn(
        'group relative bg-white rounded-2xl overflow-hidden shadow-sm hover:shadow-lg transition-all duration-300',
        'border border-gray-100 hover:border-gray-200',
        className
      )}
      whileHover={{ y: -5 }}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
    >
      <Link to={slug} className="block">
        {/* Image Container */}
        <div className="relative h-48 sm:h-56 overflow-hidden">
          <motion.img
            src={image}
            alt={title}
            className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
            loading="lazy"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
        </div>

        {/* Content */}
        <div className="p-6">
          {/* Meta Info */}
          <div className="flex items-center space-x-4 text-sm text-gray-500 mb-3">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-1" />
              <span>{date}</span>
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-1" />
              <span>{readTime}</span>
            </div>
          </div>

          {/* Title */}
          <h3 className="text-xl font-semibold text-gray-900 mb-2 group-hover:text-primary transition-colors">
            {title}
          </h3>

          {/* Excerpt */}
          <p className="text-gray-600 mb-4 line-clamp-2">
            {excerpt}
          </p>

          {/* Author and Read More */}
          <div className="flex items-center justify-between mt-auto">
            <div className="flex items-center text-sm text-gray-500">
              <User className="w-4 h-4 mr-1" />
              <span>{author}</span>
            </div>
            
            <motion.div
              className="flex items-center text-primary font-medium"
              whileHover={{ x: 5 }}
            >
              Read More
              <ChevronRight className="w-4 h-4 ml-1" />
            </motion.div>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

export default BlogCard;
