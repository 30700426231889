import { MapPin, Phone, Mail, Clock } from 'lucide-react';
import { cn } from '../../utils/cn';

interface ContactInfoProps {
  className?: string;
}

const ContactInfo = ({ className }: ContactInfoProps) => {
  const contactMethods = [
    {
      icon: <Phone className="text-primary" size={24} />,
      title: 'Phone Support',
      content: '+33 1 53 65 16 61',
      description: 'Available during business hours',
    },
    {
      icon: <Mail className="text-primary" size={24} />,
      title: 'Email Support',
      content: 'contact@1payt.com',
      description: 'We usually respond within 24 hours',
    },
    {
      icon: <MapPin className="text-primary" size={24} />,
      title: 'Office Address',
      content: '54 Avenue de la Motte Picquet',
      description: '75015 Paris, France',
    },
    {
      icon: <Clock className="text-primary" size={24} />,
      title: 'Business Hours',
      content: 'Monday - Friday: 9AM - 6PM CET',
      description: 'Weekend support available for emergencies',
    },
  ];

  return (
    <div className={cn('grid grid-cols-1 gap-8 md:grid-cols-2', className)}>
      {contactMethods.map((method) => (
        <div
          key={method.title}
          className="flex items-start space-x-4 rounded-lg border border-gray-200 p-6 transition-all duration-200 hover:border-primary/50 hover:shadow-md"
        >
          <div className="rounded-lg bg-primary/5 p-3">{method.icon}</div>
          <div>
            <h3 className="font-semibold text-gray-900">{method.title}</h3>
            <p className="mt-1 text-lg text-gray-600">{method.content}</p>
            <p className="mt-1 text-sm text-gray-500">{method.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactInfo;
